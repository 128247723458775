export const propertiesDemo = [
  {
    "__typename": "SearchHome",
    "property_id": "5242686095",
    "listing_id": "2954439683",
    "status": "for_sale",
    "photo_count": 30,
    "branding": [
      {
        "__typename": "Branding",
        "name": "CENTURY 21 New Millennium"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "22698 Athlone Dr",
        "street_name": "Athlone",
        "street_number": "22698",
        "street_suffix": "Dr",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.291662,
          "lon": -76.500458
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "single_family",
      "beds": 3,
      "baths": 2,
      "lot_sqft": 8017,
      "sqft": 1312,
      "baths_full": 2,
      "baths_full_calc": 2
    },
    "virtual_tours": [
      {
        "__typename": "VirtualTour",
        "href": "https://22698athlonedrive.nm.brokersedge.com/"
      }
    ],
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "671697",
        "name": "Cindy Ballard",
        "email": "cindy.ballard@c21nm.com",
        "href": "https://www.c21nm.com/agents/cindy-ballard/",
        "slogan": "Vested in You. Together We're Better!",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": true
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "3003815",
          "agent_name": "Cynthia Ballard",
          "office_id": "CENT2010"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2012534"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/6922091bbfd8b513dee04c014af11d56l-b3453218838s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/22698-Athlone-Dr_Great-Mills_MD_20634_M52426-86095",
    "list_price": 335000,
    "estimate": {
      "__typename": "LatestEstimate",
      "estimate": 339700
    },
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "last_sold_date": "2008-04-16",
    "list_date": "2023-04-11T14:41:05Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker",
        "co_broke"
      ]
    },
    "last_sold_price": 269900
  },
  {
    "__typename": "SearchHome",
    "property_id": "5049821025",
    "listing_id": "2954274306",
    "status": "for_sale",
    "photo_count": 47,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Berkshire Hathaway HomeServices PenFed Realty"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "45529 Shannon St",
        "street_name": "Shannon",
        "street_number": "45529",
        "street_suffix": "St",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.289773,
          "lon": -76.500653
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "single_family",
      "beds": 3,
      "baths": 2,
      "lot_sqft": 7760,
      "sqft": 2023,
      "baths_full": 2,
      "baths_full_calc": 2
    },
    "virtual_tours": [
      {
        "__typename": "VirtualTour",
        "href": "https://www.dropbox.com/scl/fo/zqoiqebp1eeaawy1ffimm/h/45529%20Shannon%20St%20HD.mp4?dl=0&rlkey=l2eizof21vju86xw6wdjlf2ej"
      }
    ],
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "2973590",
        "name": "Karen Reisdorf",
        "email": "reisdorfkaren@gmail.com",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": true,
      "is_contingent": true
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "3093340",
          "agent_name": "Karen Reisdorf",
          "office_id": "PFED53"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2012246"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/d371f9d33a4b0526214f93858692bb60l-m1318577955s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/45529-Shannon-St_Great-Mills_MD_20634_M50498-21025",
    "list_price": 359900,
    "estimate": {
      "__typename": "LatestEstimate",
      "estimate": 356600
    },
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "last_sold_date": "2006-11-29",
    "list_date": "2023-04-06T04:13:40Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker",
        "co_broke"
      ]
    },
    "last_sold_price": 325000
  },
  {
    "__typename": "SearchHome",
    "property_id": "6369419422",
    "listing_id": "2954228068",
    "status": "for_sale",
    "photo_count": 21,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Exp Realty, LLC"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "45633 Reservoir Way",
        "street_name": "Reservoir",
        "street_number": "45633",
        "street_suffix": "Way",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.278402,
          "lon": -76.497097
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "single_family",
      "beds": 4,
      "baths": 3,
      "lot_sqft": 8090,
      "sqft": 2500,
      "baths_full": 2,
      "baths_half": 1,
      "baths_full_calc": 2,
      "baths_partial_calc": 1
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "3202365",
        "name": "Cathy Austin",
        "email": "Cathy.Austin@ExpRealty.com",
        "href": "CathyAustinAndAssociates.com",
        "slogan": "We Go The Distance For You!",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": true
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "3247680",
          "agent_name": "Cathy Austin",
          "office_id": "EXXP1"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2012430"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/fed461514edd33cc1735a565353dec86l-m1952969110s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/45633-Reservoir-Way_Great-Mills_MD_20634_M63694-19422",
    "list_price": 420000,
    "estimate": {
      "__typename": "LatestEstimate",
      "estimate": 391000
    },
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "last_sold_date": "2022-05-19",
    "list_date": "2023-04-05T04:08:34Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker",
        "co_broke"
      ]
    },
    "last_sold_price": 390000
  },
  {
    "__typename": "SearchHome",
    "property_id": "6222107151",
    "listing_id": "2953839463",
    "status": "for_sale",
    "photo_count": 29,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Home Towne Real Estate"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "45689 Frigate Pl",
        "street_name": "Frigate",
        "street_number": "45689",
        "street_suffix": "Pl",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.272903,
          "lon": -76.495215
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "single_family",
      "beds": 4,
      "baths": 2,
      "lot_sqft": 11715,
      "sqft": 1874,
      "baths_full": 2,
      "baths_full_calc": 2
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "1519744",
        "name": "Becky King",
        "email": "bking@hometowneteam.com",
        "href": "http://agentbeckyking.net",
        "slogan": "Agent",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false,
      "is_contingent": true
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "106768",
          "agent_name": "Rebecca King",
          "office_id": "HOTR1"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2012268"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/cd69262fa0067bb3778ab18005d3d0d0l-m768945647s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/45689-Frigate-Pl_Great-Mills_MD_20634_M62221-07151",
    "list_price": 340000,
    "estimate": {
      "__typename": "LatestEstimate",
      "estimate": 337200
    },
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "last_sold_date": "2018-06-28",
    "list_date": "2023-03-23T19:43:52Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker",
        "co_broke"
      ]
    },
    "last_sold_price": 250000
  },
  {
    "__typename": "SearchHome",
    "property_id": "5324857498",
    "listing_id": "2953811283",
    "status": "for_sale",
    "photo_count": 45,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Infinitas Realty"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "20801 Brook Mill Ct",
        "street_name": "Brook Mill",
        "street_number": "20801",
        "street_suffix": "Ct",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.241127,
          "lon": -76.499297
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "single_family",
      "beds": 4,
      "baths": 3,
      "lot_sqft": 13056,
      "sqft": 3716,
      "baths_full": 2,
      "baths_half": 1,
      "baths_full_calc": 2,
      "baths_partial_calc": 1
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "2705096",
        "name": "Matt Burgan",
        "email": "Matt@MattBurganHomes.com",
        "href": "www.MattBurganHomes.com",
        "slogan": "Unparalleled service is my standard",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "3109334",
          "agent_name": "Matthew Burgan",
          "office_id": "INFINI1",
          "office_name": "Infinitas Realty"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2011960"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/5b3267568e25f70e27428573adf60ab0l-m30422025s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/20801-Brook-Mill-Ct_Great-Mills_MD_20634_M53248-57498",
    "list_price": 535000,
    "estimate": {
      "__typename": "LatestEstimate",
      "estimate": 524500
    },
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "last_sold_date": "2006-12-20",
    "list_date": "2023-03-23T04:12:51Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "basic_opt_in",
      "products": [
        "core.agent",
        "co_broke"
      ]
    },
    "last_sold_price": 494862
  },
  {
    "__typename": "SearchHome",
    "property_id": "6115675619",
    "listing_id": "2953760340",
    "status": "for_sale",
    "photo_count": 10,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Berkshire Hathaway HomeServices PenFed Realty"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "22060 Clipper Dr",
        "street_name": "Clipper",
        "street_number": "22060",
        "street_suffix": "Dr",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.273225,
          "lon": -76.496527
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "single_family",
      "beds": 3,
      "baths": 3,
      "lot_sqft": 11101,
      "sqft": 2598,
      "baths_full": 2,
      "baths_half": 1,
      "baths_full_calc": 2,
      "baths_partial_calc": 1
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "1232798",
        "name": "Michael  Stanton",
        "email": "michael.stanton@mcnelisgroup.com",
        "href": "http://www.mcnelisgroup.com/michael-stanton/",
        "slogan": "\"Making people's dreams of homeownership a reality\"",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_price_reduced": true,
      "is_new_listing": false,
      "is_pending": true
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "108784",
          "agent_name": "Lawrence Stanton",
          "office_id": "PFED65",
          "office_name": "Berkshire Hathaway HomeServices PenFed Realty"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2012032"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/cdaa49fcfeb0fc370228adc3c9ca5ab8l-m898832356s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/22060-Clipper-Dr_Great-Mills_MD_20634_M61156-75619",
    "list_price": 285000,
    "price_reduced_amount": 40000,
    "estimate": {
      "__typename": "LatestEstimate",
      "estimate": 321200
    },
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "last_sold_date": "1991-09-05",
    "list_date": "2023-03-21T19:44:33Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "basic_opt_in",
      "products": [
        "core.agent",
        "co_broke"
      ]
    },
    "last_sold_price": 110900
  },
  {
    "__typename": "SearchHome",
    "property_id": "5526910993",
    "listing_id": "2953324013",
    "status": "for_sale",
    "photo_count": 30,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Redfin Corp"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "45535 Coronado Dr",
        "street_name": "Coronado",
        "street_number": "45535",
        "street_suffix": "Dr",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.270989,
          "lon": -76.500444
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "single_family",
      "beds": 4,
      "baths": 2,
      "lot_sqft": 15000,
      "sqft": 1296,
      "baths_full": 2,
      "baths_full_calc": 2
    },
    "matterport": true,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "2063345",
        "name": "Lesa Clark",
        "email": "lesa@lnf.com",
        "href": "http://www.lesaclark.lnfre.com/",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false,
      "is_contingent": true
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "142127",
          "agent_name": "Lesa Clark",
          "office_id": "REFC2"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2012018"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/f2ba70374c5cc7b810d5dc1dd67677fbl-m1416194360s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/45535-Coronado-Dr_Great-Mills_MD_20634_M55269-10993",
    "list_price": 240000,
    "estimate": {
      "__typename": "LatestEstimate",
      "estimate": 236900
    },
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "last_sold_date": "1993-06-08",
    "list_date": "2023-03-09T05:21:00Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker",
        "co_broke"
      ]
    },
    "last_sold_price": 55000
  },
  {
    "__typename": "SearchHome",
    "property_id": "6118519203",
    "listing_id": "2953071515",
    "status": "for_sale",
    "photo_count": 16,
    "branding": [
      {
        "__typename": "Branding",
        "photo": "https://ap.rdcpix.com/6ff384d8446d7505df60dbad3c5f2488o-b1637540070s.jpg",
        "name": "EXIT LANDMARK REALTY (White Plains)"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "45867 Broun Ter",
        "street_name": "Broun",
        "street_number": "45867",
        "street_suffix": "Ter",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.280059,
          "lon": -76.488705
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "single_family",
      "beds": 6,
      "baths": 3,
      "lot_sqft": 19009,
      "sqft": 2464,
      "baths_full": 3,
      "baths_full_calc": 3
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "4622685",
        "name": "Stefanie Odom",
        "email": "stefsellssomd@gmail.com",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false,
      "is_contingent": true
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "3291562",
          "agent_name": "Stefanie Odom",
          "office_id": "ELMR1",
          "office_name": "Exit Landmark Realty"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2011818"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/d192cad1aef0ed344c476d2bb16a5598l-b3381196329s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/45867-Broun-Ter_Great-Mills_MD_20634_M61185-19203",
    "list_price": 399900,
    "estimate": {
      "__typename": "LatestEstimate",
      "estimate": 388800
    },
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "last_sold_date": "2004-07-08",
    "list_date": "2023-03-05T05:11:04Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "advantage_brand",
      "products": [
        "core.agent",
        "core.broker",
        "listing_owner_brand.broker",
        "co_broke"
      ]
    },
    "last_sold_price": 239900
  },
  {
    "__typename": "SearchHome",
    "property_id": "9924033162",
    "listing_id": "2952723955",
    "status": "for_sale",
    "photo_count": 9,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Classic Realty, Ltd."
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "22050 Mojave Dr",
        "street_name": "Mojave",
        "street_number": "22050",
        "street_suffix": "Dr",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.273031,
          "lon": -76.49995
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "single_family",
      "beds": 4,
      "baths": 2,
      "lot_sqft": 15000,
      "sqft": 1296,
      "baths_full": 2,
      "baths_full_calc": 2
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "223903",
        "name": "Christopher White",
        "email": "calan2000@yahoo.com",
        "href": "http://www.cbmove.com/christopherwhite?WT.mc_ID=101311000000000",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "87025",
          "agent_name": "Christopher White",
          "office_id": "CLSS1"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2011912"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/235e4f98b53b129cf434f4688ddba948l-m2214562315s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/22050-Mojave-Dr_Great-Mills_MD_20634_M99240-33162",
    "list_price": 245000,
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "list_date": "2023-02-24T02:10:32Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker",
        "co_broke"
      ]
    }
  },
  {
    "__typename": "SearchHome",
    "property_id": "9460079807",
    "listing_id": "2952596691",
    "status": "for_sale",
    "photo_count": 4,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Long & Foster Prince Frederick"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "Flat Iron and Raggedy Lane Rd",
        "street_name": "Flat Iron",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.22154,
          "lon": -76.49039
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "land",
      "baths": 0,
      "lot_sqft": 524027
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "295694",
        "name": "Sheri Goldman",
        "email": "sheri.goldman@longandfoster.com",
        "href": "http://www.sheri-goldman.com",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false,
      "is_contingent": true
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "48310",
          "agent_name": "Sheri Goldman",
          "office_id": "LNG130"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2011858"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/77bc58cfce507d7717eee7ea2da1022al-m1776193071s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/Flat-Iron_Great-Mills_MD_20634_M94600-79807",
    "list_price": 150000,
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "list_date": "2023-02-21T13:35:12Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker"
      ]
    }
  },
  {
    "__typename": "SearchHome",
    "property_id": "6584157154",
    "listing_id": "2952581244",
    "status": "for_sale",
    "photo_count": 4,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Berkshire Hathaway HomeServices PenFed Realty"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "20626 Adkins Rd",
        "street_name": "Adkins",
        "street_number": "20626",
        "street_suffix": "Rd",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.231397,
          "lon": -76.487196
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "land",
      "baths": 0,
      "lot_sqft": 131551
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "3249969",
        "name": "Helen Mattingly Wernecke",
        "email": "helen.wernecke@penfedrealty.com",
        "href": "http://www.hmwproperties.com",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "3243305",
          "agent_name": "Helen Mattingly Wernecke",
          "office_id": "PFED65"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2011834"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/bd72c42ce92eb50892ad79f43d366305l-m2527537052s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/20626-Adkins-Rd_Great-Mills_MD_20634_M65841-57154",
    "list_price": 90000,
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "list_date": "2023-02-20T21:47:41Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "basic_opt_in",
      "products": [
        "core.agent"
      ]
    }
  },
  {
    "__typename": "SearchHome",
    "property_id": "9757731095",
    "listing_id": "2952580155",
    "status": "for_sale",
    "photo_count": 4,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Berkshire Hathaway HomeServices PenFed Realty"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "Adkins Rd",
        "street_name": "Adkins",
        "street_suffix": "Rd",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.22996813,
          "lon": -76.48579317
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "land",
      "baths": 0,
      "lot_sqft": 800633
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "3249969",
        "name": "Helen Mattingly Wernecke",
        "email": "helen.wernecke@penfedrealty.com",
        "href": "http://www.hmwproperties.com",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "3243305",
          "agent_name": "Helen Mattingly Wernecke",
          "office_id": "PFED65"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2011836"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/23d2a91f63a6d01632a01b55f45d5c47l-m2527537052s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/Adkins-Rd_Great-Mills_MD_20634_M97577-31095",
    "list_price": 135000,
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "list_date": "2023-02-20T21:28:27Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "basic_opt_in",
      "products": [
        "core.agent"
      ]
    }
  },
  {
    "__typename": "SearchHome",
    "property_id": "9788825695",
    "listing_id": "2952562289",
    "status": "for_sale",
    "photo_count": 30,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Berkshire Hathaway HomeServices PenFed Realty"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "20770 Langley Rd",
        "street_name": "Langley",
        "street_number": "20770",
        "street_suffix": "Rd",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.238157,
          "lon": -76.492884
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "single_family",
      "beds": 3,
      "baths": 2,
      "lot_sqft": 45302,
      "sqft": 1772,
      "baths_full": 2,
      "baths_full_calc": 2
    },
    "virtual_tours": [
      {
        "__typename": "VirtualTour",
        "href": "https://mls.homejab.com/property/20770-langley-rd-lexington-park-md-20653-usa"
      }
    ],
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "3249969",
        "name": "Helen Mattingly Wernecke",
        "email": "helen.wernecke@penfedrealty.com",
        "href": "http://www.hmwproperties.com",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "3243305",
          "agent_name": "Helen Mattingly Wernecke",
          "office_id": "PFED65"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2011790"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/a06db3708808ae6c4bc66583da1a4d35l-m2234079005s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/20770-Langley-Rd_Great-Mills_MD_20634_M97888-25695",
    "list_price": 275000,
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "list_date": "2023-02-19T22:04:31Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "basic_opt_in",
      "products": [
        "core.agent",
        "co_broke"
      ]
    }
  },
  {
    "__typename": "SearchHome",
    "property_id": "6680303285",
    "listing_id": "2952429024",
    "status": "for_sale",
    "photo_count": 37,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Coldwell Banker Jay Lilly Real Estate"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "45821 Circle Dr",
        "street_name": "Circle",
        "street_number": "45821",
        "street_suffix": "Dr",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.263009,
          "lon": -76.490381
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "single_family",
      "beds": 4,
      "baths": 4,
      "lot_sqft": 29185,
      "sqft": 2700,
      "baths_full": 3,
      "baths_half": 1,
      "baths_full_calc": 3,
      "baths_partial_calc": 1
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "223899",
        "name": "William Harris",
        "email": "billharriscb@gmail.com",
        "href": "http://www.cbmove.com/WHarris?WT.mc_ID=101311000000000",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false,
      "is_contingent": true
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "110325",
          "agent_name": "William Harris",
          "office_id": "CBJL2"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2011774"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/d19a63755cac7ecf6dbb386c361a7188l-m4204390478s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/45821-Circle-Dr_Great-Mills_MD_20634_M66803-03285",
    "list_price": 329900,
    "estimate": {
      "__typename": "LatestEstimate",
      "estimate": 341900
    },
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "last_sold_date": "2006-04-17",
    "list_date": "2023-02-15T14:31:40Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker",
        "co_broke"
      ]
    },
    "last_sold_price": 317000
  },
  {
    "__typename": "SearchHome",
    "property_id": "5932886171",
    "listing_id": "2952374933",
    "status": "for_sale",
    "photo_count": 5,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Keller Williams Preferred Properties"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "22127 Saint Josephs Cir",
        "street_name": "Saint Josephs",
        "street_number": "22127",
        "street_suffix": "Cir",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.274896,
          "lon": -76.491138
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "sub_type": "townhouse",
      "type": "townhomes",
      "beds": 3,
      "baths": 3,
      "lot_sqft": 5527,
      "sqft": 1461,
      "baths_full": 2,
      "baths_half": 1,
      "baths_full_calc": 2,
      "baths_partial_calc": 1
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "1902125",
        "name": "Sharyn C Ahmad",
        "email": "sharyncrump@gmail.com",
        "href": "http://readyforealestate.com",
        "slogan": "Ready For Real Estate!!",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_price_reduced": true,
      "is_new_listing": false
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "3064723",
          "agent_name": "Sharyn Ahmad",
          "office_id": "KWPP1"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2011752"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/6dcc2561d49ee50f07f8d63d530422edl-m2141016724s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/22127-Saint-Josephs-Cir_Great-Mills_MD_20634_M59328-86171",
    "list_price": 160000,
    "price_reduced_amount": 15000,
    "estimate": {
      "__typename": "LatestEstimate",
      "estimate": 183300
    },
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "last_sold_date": "2004-04-19",
    "list_date": "2023-02-13T20:47:40Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker",
        "co_broke"
      ]
    },
    "last_sold_price": 140000
  },
  {
    "__typename": "SearchHome",
    "property_id": "6719581589",
    "listing_id": "2951528499",
    "status": "for_sale",
    "photo_count": 20,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Keller Williams Chantilly Ventures, LLC"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "45846 Nancy Ln",
        "street_name": "Nancy",
        "street_number": "45846",
        "street_suffix": "Ln",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.263988,
          "lon": -76.489153
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "single_family",
      "beds": 4,
      "baths": 3,
      "lot_sqft": 22310,
      "sqft": 1924,
      "baths_full": 2,
      "baths_half": 1,
      "baths_full_calc": 2,
      "baths_partial_calc": 1
    },
    "virtual_tours": [
      {
        "__typename": "VirtualTour",
        "href": "https://video214.com/play/1zRzydzsTz07wgFpaSbc3w/s/dark"
      }
    ],
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "981723",
        "name": "Reynolds EmpowerHome Team",
        "email": "info@rtrsells.com",
        "href": "http://www.RTRSells.com",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_price_reduced": false,
      "is_new_listing": false,
      "is_contingent": true
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "141480",
          "agent_name": "Sarah Reynolds",
          "office_id": "KWR4"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2011218"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/18e19d5b2d8fe8a395b47a47adc9ac31l-m2284757391s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/45846-Nancy-Ln_Great-Mills_MD_20634_M67195-81589",
    "list_price": 300000,
    "price_reduced_amount": 15000,
    "estimate": {
      "__typename": "LatestEstimate",
      "estimate": 318800
    },
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "last_sold_date": "2021-03-17",
    "list_date": "2023-01-18T05:13:13Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker",
        "co_broke"
      ]
    },
    "last_sold_price": 293000
  },
  {
    "__typename": "SearchHome",
    "property_id": "6139339492",
    "listing_id": "2950268265",
    "status": "for_sale",
    "photo_count": 33,
    "branding": [
      {
        "__typename": "Branding",
        "name": "CENTURY 21 Downtown"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "22090 Saint Clements Cir",
        "street_name": "Saint Clements",
        "street_number": "22090",
        "street_suffix": "Cir",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.274387,
          "lon": -76.492079
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "sub_type": "townhouse",
      "type": "townhomes",
      "beds": 3,
      "baths": 3,
      "sqft": 1362,
      "baths_full": 2,
      "baths_half": 1,
      "baths_full_calc": 2,
      "baths_partial_calc": 1
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "1043644",
        "name": "James Sapia",
        "email": "james.sapia@century21.com",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_price_reduced": false,
      "is_new_listing": false,
      "is_contingent": true
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "3009708",
          "agent_name": "James Sapia",
          "office_id": "CDWN1"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2010692"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/28bde7a97c75af408d01e544d535f61dl-m1250880979s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/22090-Saint-Clements-Cir_Great-Mills_MD_20634_M61393-39492",
    "list_price": 249900,
    "price_reduced_amount": 99,
    "estimate": {
      "__typename": "LatestEstimate",
      "estimate": 250000
    },
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "last_sold_date": "1995-11-14",
    "list_date": "2022-11-27T17:22:50Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker",
        "co_broke"
      ]
    },
    "last_sold_price": 96950
  },
  {
    "__typename": "SearchHome",
    "property_id": "9736547433",
    "listing_id": "2950131735",
    "status": "for_sale",
    "photo_count": 9,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Exp Realty"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "22146 Indian Brg",
        "street_name": "Indian",
        "street_number": "22146",
        "street_suffix": "Brg",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.275274,
          "lon": -76.540307
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "land",
      "baths": 0,
      "lot_sqft": 43560
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "3369598",
        "name": "James Barnes",
        "email": "james.barnes@c21nm.com",
        "href": "http://jamesbarnes.c21nm.com/",
        "slogan": "Go Platinum for Quality Service. The Platinum Group",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_price_reduced": false,
      "is_new_listing": false
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "3251612",
          "agent_name": "James Barnes",
          "office_id": "EXXPR1"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2010570"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/78c0e7907888fb6829ff909a1a8ba93dl-m1830849176s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/22146-Indian-Brg_Great-Mills_MD_20634_M97365-47433",
    "list_price": 175000,
    "price_reduced_amount": 10000,
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "list_date": "2022-11-19T12:59:14Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker"
      ]
    }
  },
  {
    "__typename": "SearchHome",
    "property_id": "6468007435",
    "listing_id": "2943415484",
    "status": "for_sale",
    "photo_count": 1,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Bml Properties Realty, Llc."
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "46160 N Greens Rest Dr",
        "street_name": "Greens Rest",
        "street_number": "46160",
        "street_suffix": "Dr",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.208239,
          "lon": -76.478534
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "single_family",
      "beds": 4,
      "baths": 4,
      "lot_sqft": 60984,
      "sqft": 3088,
      "baths_full": 3,
      "baths_half": 1,
      "baths_full_calc": 3,
      "baths_partial_calc": 1
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "517125",
        "name": "BML Group",
        "email": "mail@bmlproperties.com",
        "href": "http://www.bmlproperties.com/index.html",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "119496",
          "agent_name": "Monique Lucas",
          "office_id": "BMLP1"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2006922"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/4396e2fe07cfbce27a2adb280365b208l-m2546370035s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/46160-N-Greens-Rest-Dr_Great-Mills_MD_20634_M64680-07435",
    "list_price": 550000,
    "estimate": {
      "__typename": "LatestEstimate",
      "estimate": 547100
    },
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "last_sold_date": "2005-08-23",
    "list_date": "2022-05-23T04:13:03Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker",
        "co_broke"
      ]
    },
    "last_sold_price": 430000
  },
  {
    "__typename": "SearchHome",
    "property_id": "9377438426",
    "listing_id": "2940452053",
    "status": "for_sale",
    "photo_count": 26,
    "branding": [
      {
        "__typename": "Branding",
        "name": "O'Brien Realty - California/Lexington Park Office"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "46138 Boothe Farm",
        "street_name": "Boothe Farm",
        "street_number": "46138",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.214682,
          "lon": -76.477803
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "single_family",
      "beds": 3,
      "baths": 2,
      "lot_sqft": 6011280,
      "sqft": 1950,
      "baths_full": 2,
      "baths_full_calc": 2
    },
    "virtual_tours": [
      {
        "__typename": "VirtualTour",
        "href": "https://www.youtube.com/watch?v=BJl9kLpXT1M&authuser=0"
      }
    ],
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "531036",
        "name": "Dawn Wood",
        "email": "ldawnwood@gmail.com",
        "href": "http://www.calldawnwood.com",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "63614",
          "agent_name": "Lisa Wood",
          "office_id": "OBRE1"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2005432"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/176f83ca2722c07ef5e6d7c46a4f13b0l-m1493882576s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/46138-Boothe-Farm_Great-Mills_MD_20634_M93774-38426",
    "list_price": 3000000,
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "list_date": "2022-02-22T15:47:17Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker",
        "co_broke"
      ]
    }
  },
  {
    "__typename": "SearchHome",
    "property_id": "9555844075",
    "listing_id": "2939673981",
    "status": "for_sale",
    "photo_count": 4,
    "branding": [
      {
        "__typename": "Branding",
        "photo": "https://ap.rdcpix.com/bfea206aede5fbedeff948700441d90fo-b2274465705s.jpg",
        "name": "RE MAX One"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "Goldfinch Dr",
        "street_name": "Goldfinch",
        "street_suffix": "Dr",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.27673701,
          "lon": -76.48364335
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "land",
      "baths": 0,
      "lot_sqft": 2593998
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "1655599",
        "name": "Robert Wustner",
        "email": "robert.wustner@remax.net",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "55722",
          "agent_name": "Robert Wustner",
          "office_id": "RMXO3",
          "office_name": "RE/MAX One"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2004794"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/80d2f662926620419afd439f919c323cl-b2273778018s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/Goldfinch-Dr_Great-Mills_MD_20634_M95558-44075",
    "list_price": 3250000,
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "list_date": "2022-02-01T16:35:34Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "advantage_brand",
      "products": [
        "core.agent",
        "core.broker",
        "listing_owner_brand.broker"
      ]
    }
  },
  {
    "__typename": "SearchHome",
    "property_id": "9207161194",
    "listing_id": "2932812926",
    "status": "for_sale",
    "photo_count": 26,
    "branding": [
      {
        "__typename": "Branding",
        "name": "O'Brien Realty - California/Lexington Park Office"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "46138 Boothe Farm",
        "street_name": "Boothe Farm",
        "street_number": "46138",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.214682,
          "lon": -76.477803
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "land",
      "baths": 0,
      "lot_sqft": 6011280
    },
    "virtual_tours": [
      {
        "__typename": "VirtualTour",
        "href": "https://www.youtube.com/watch?v=BJl9kLpXT1M&authuser=0"
      }
    ],
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "531036",
        "name": "Dawn Wood",
        "email": "ldawnwood@gmail.com",
        "href": "http://www.calldawnwood.com",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "63614",
          "agent_name": "Lisa Wood",
          "office_id": "OBRE1",
          "office_name": "O Brien Realty"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2001278"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/fd0be6d5f8838c9ff3412d9bad49055fl-m1493882576s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/46138-Boothe-Farm_Great-Mills_MD_20634_M92071-61194",
    "list_price": 3000000,
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "list_date": "2021-08-07T13:52:42Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker"
      ]
    }
  },
  {
    "__typename": "SearchHome",
    "property_id": "9609580389",
    "listing_id": "2932697449",
    "status": "for_sale",
    "photo_count": 26,
    "branding": [
      {
        "__typename": "Branding",
        "name": "O'Brien Realty - California/Lexington Park Office"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "46138 Boothe Farm",
        "street_name": "Boothe Farm",
        "street_number": "46138",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.214682,
          "lon": -76.477803
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "farm",
      "beds": 3,
      "baths": 2,
      "lot_sqft": 6011280,
      "sqft": 1950,
      "baths_full": 2,
      "baths_full_calc": 2
    },
    "virtual_tours": [
      {
        "__typename": "VirtualTour",
        "href": "https://www.youtube.com/watch?v=BJl9kLpXT1M&authuser=0"
      }
    ],
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "531036",
        "name": "Dawn Wood",
        "email": "ldawnwood@gmail.com",
        "href": "http://www.calldawnwood.com",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false,
      "is_pending": true
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "63614",
          "agent_name": "Lisa Wood",
          "office_id": "OBRE1"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2001236"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/35464ffd626994142e0f7deaa2322af4l-m1493882576s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/46138-Boothe-Farm_Great-Mills_MD_20634_M96095-80389",
    "list_price": 3000000,
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "list_date": "2021-08-05T19:30:04Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker",
        "co_broke"
      ]
    }
  },
  {
    "__typename": "SearchHome",
    "property_id": "9410857969",
    "listing_id": "2931988271",
    "status": "for_sale",
    "photo_count": 5,
    "branding": [
      {
        "__typename": "Branding",
        "name": "Hogan Companies Residential"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "Bay Ridge Rd",
        "street_name": "Bay Ridge",
        "street_suffix": "Rd",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.236404,
          "lon": -76.48960422
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "land",
      "baths": 0,
      "lot_sqft": 288367
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "0",
        "name": "Kevin Michael Setzer",
        "email": "ksetzer@hogancompanies.com",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_new_listing": false,
      "is_pending": true
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "3324968",
          "agent_name": "Kevin Setzer",
          "office_id": "HOGG1"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM2000844"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/9784436a18a651667fcff79fbaac1879l-m1837369673s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/Bay-Ridge-Rd_Great-Mills_MD_20634_M94108-57969",
    "list_price": 1350000,
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "list_date": "2021-07-20T18:29:08Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.broker"
      ]
    }
  },
  {
    "__typename": "SearchHome",
    "property_id": "5301537759",
    "listing_id": "650839078",
    "status": "for_sale",
    "photo_count": 5,
    "branding": [
      {
        "__typename": "Branding",
        "name": "O'Brien Realty - California/Lexington Park Office"
      }
    ],
    "location": {
      "__typename": "SearchHomeLocation",
      "address": {
        "__typename": "SearchHomeAddress",
        "city": "Great Mills",
        "line": "Tammie Dr",
        "street_name": "Tammie",
        "street_suffix": "Dr",
        "country": "USA",
        "postal_code": "20634",
        "state_code": "MD",
        "state": "Maryland",
        "coordinate": {
          "__typename": "Coordinate",
          "lat": 38.26194275,
          "lon": -76.49719708
        }
      },
      "street_view_url": "",
      "county": {
        "__typename": "HomeCounty",
        "fips_code": "24037"
      }
    },
    "description": {
      "__typename": "SearchHomeDescription",
      "type": "land",
      "baths": 0,
      "lot_sqft": 2032074
    },
    "matterport": false,
    "advertisers": [
      {
        "__typename": "HomeAdvertiser",
        "fulfillment_id": "1483871",
        "name": "Gordon Stellway",
        "email": "gstellway@yahoo.com",
        "type": "seller"
      }
    ],
    "flags": {
      "__typename": "HomeFlags",
      "is_price_reduced": true,
      "is_new_listing": false
    },
    "source": {
      "__typename": "MlsSource",
      "agents": [
        {
          "__typename": "MlsAgent",
          "id": "PHPA",
          "agent_id": "71145",
          "agent_name": "Gordon Stellway",
          "office_id": "OBRE1"
        }
      ],
      "id": "PHPA",
      "type": "mls",
      "listing_id": "MDSM150518"
    },
    "primary_photo": {
      "__typename": "Photo",
      "href": "https://ap.rdcpix.com/02fcd36fcb619c0eaa420a773c46e248l-m3350624548s.jpg"
    },
    "href": "https://www.realtor.com/realestateandhomes-detail/Tammie-Dr_Great-Mills_MD_20634_M53015-37759",
    "list_price": 297500,
    "price_reduced_amount": 51500,
    "lead_attributes": {
      "__typename": "LeadAttributes",
      "lead_type": "co_broke",
      "show_contact_an_agent": true,
      "opcity_lead_attributes": {
        "__typename": "OpCityLeadAttributes",
        "flip_the_market_enabled": false
      }
    },
    "list_date": "2019-02-01T21:59:15Z",
    "products": {
      "__typename": "ProductSummary",
      "brand_name": "essentials",
      "products": [
        "core.agent",
        "core.broker"
      ]
    }
  }
]
