

const CardLoading = ({layout}) =>{

    return(
        <div className={`av-loading-skeleton ${layout}`}>
            <div className="loading-container">
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                </div>

                <div className="loading-container">
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                </div>

                <div className="loading-container">
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                </div>

                <div className="loading-container">
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                </div>

                <div className="loading-container">
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                </div>

                <div className="loading-container">
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                </div>

                <div className="loading-container">
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                </div>


                <div className="loading-container">
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                </div>

                <div className="loading-container">
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                </div>

                <div className="loading-container">
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                        <div className="loading-text"></div>
                </div>
        </div>
        
    )

}

export default CardLoading