import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { NotificationProvider } from './context/notification/Notification.jsx';
import "./styles/fonts/Inter Font Family/style.css"
import "./styles/index.js"
import './App.css';

import {BrowserRouter as Router} from "react-router-dom"
import { UserProvider } from './context/user/UserContext';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

   //  <React.StrictMode> 
    <Router> 
      <UserProvider>
      <NotificationProvider>
        <App />
      </NotificationProvider>
      </UserProvider>
    </Router>
   // </React.StrictMode>   
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
