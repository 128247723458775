

const PageLoading = () =>{

    return(
        <div className="av-loading-skeleton loading-page container-medium" style={{maxWidth: "1200px", margin: "auto"}}>
            <div className="loading-container">

                        <div className="loading-photo">
                            <div className="loading-text"></div>
                            <div className="loading-text"></div>
                            <div className="loading-text"></div>
                        </div>

                        <div className="loading-content-container">

                             <div className="loading-content">

                                <div className="loading-prop-info">
                                    <div className="loading-text"></div>
                                    <div className="loading-text"></div>
                                    <div className="loading-text"></div>
                                </div>

                                <div className="loading-prop-info">
                                    <div className="loading-text"></div>
                                    <div className="loading-text"></div>
                                    <div className="loading-text"></div>
                                </div>

                                <div className="loading-prop-info">
                                    <div className="loading-text"></div>
                                    <div className="loading-text"></div>
                                    <div className="loading-text"></div>
                                </div>
                                            
                             </div>

                            <div className="loading-agent-content">
                                    <div className="loading-text"></div>
                                    <div className="loading-text"></div>
                                    <div className="loading-text"></div>
                                    <div className="loading-text"></div>
                            </div>

                        </div>

        
                </div>
        </div>
        
    )

}

export default PageLoading