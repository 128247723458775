
 export   const salePriceOptions = [
   10000,
   20000,
   30000,
   40000,
   50000,
   100000,
   150000,
   200000,
   250000,
   300000,
   350000,
   400000,
   450000,
   500000,
   600000,
   700000,
   800000,
   900000,
   1000000,
   ]

 export const rentPriceOptions = [ 
      700,
      1000,
      1100,
      1250,
      1350,
      1450,
      1550,
      1650,
      1700,
      1800,
      1900,
      2000,
      3000
   ]