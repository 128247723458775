

const SplitLayout = ({ children }) => {

    return (
    <>
        <div className="split-window">
            {children}
        </div> 
    </>
    );
  };
  
  export default SplitLayout;