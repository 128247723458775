import { useState, useCallback, useEffect, useRef } from "react"
import { propertyTypeList } from "../../constants/listOptions/propertyType"
import { cleanInput } from "../../Util/cleanInput"
import { salePriceOptions } from "../../constants/listOptions/priceRange"
import { formatNumber } from "../../Util/formatNumber"
import Autocomplete from "../map/AutoComplete"


const QuickSearch = () =>{

   const [searchUrl, setSearchUrl] =  useState("listings")

   const [typeDrowdown, setTypeDropdown] = useState(false)
   const [rangeDrowdown, setRangeDropdown] = useState(false)
   const [typeValue, setTypeValue] = useState("")
   const [priceRangeValue, setPriceRangeValue] = useState("")
   const [minPriceValue, setMinPriceValue] = useState("")
   const [minDropdown , setMinDropdown] = useState(false)
   const [maxPriceValue, setMaxPriceValue] = useState("")
   const [maxDropdown , setMaxDropdown] = useState(false)

   const typeWindowRef = useRef(null);
   const rangeWindowRef = useRef(null);

   const [search, setSearch] = useState({
    city_zip : "",
    city: "Jacksonville",
    state_code: "FL",
    type: [],
    status: [],
    list_price: {min: 0, max: 0},
    baths: {min: 0, max: 0},
    beds : {min: 0, max: 0}
 })

 useEffect(()=>{
    const addTypeEvent = (e) =>{
        if(typeWindowRef.current && !typeWindowRef.current.contains(e.target)){
            setTypeDropdown(false)
        }
    }

    const addRangeEvent = (e) =>{
        if(rangeWindowRef.current && !rangeWindowRef.current.contains(e.target)){
            setRangeDropdown(false)
        }
    }

    document.addEventListener("click",addTypeEvent, true)
    document.addEventListener("click",addRangeEvent, true)

},[])

 useEffect(()=>{
    const encodedSearch = encodeURIComponent(JSON.stringify(search))
    setSearchUrl("/listings?search=" + encodedSearch)
 },[search])

 useEffect(()=>{

    const min = search.list_price.min
    const max = search.list_price.max

    if(min === 0 && max === 0){
        setPriceRangeValue(``)
        return
    }

    if(min > 0 && max > 0){
        setPriceRangeValue(`$${formatNumber(min)} - ${formatNumber(max)}`)
        return
    }

    if( (min === 0 || !min) || (max === 0 || !max)){

        if(min){
            setPriceRangeValue(`$${formatNumber(min)}`)
            return
        }

        if(max){
            setPriceRangeValue(`$${formatNumber(max)}`)
            return
        }
        
        return
    }
    setPriceRangeValue("")

 },[search.list_price.min, search.list_price.max])

 const  updateField = useCallback((key, value) =>{
         
    const formFieldCopy = {...search}
    const types = formFieldCopy.type

    if(key === "type"){

            if(types.includes(value)){
                formFieldCopy.type =  types.filter((type) => type !== value)
            }else{
                formFieldCopy.type.push(value)
            }
            setTypeValue(handleTypeValue(formFieldCopy.type))
            setSearch(formFieldCopy)
        }

    },[search])

    const handleTypeValue = (array) =>{

            if(Array.isArray(array) && array.length === 1){

                return cleanInput(array[0])

            }

            if(Array.isArray(array) && array.length > 1){

                return `Property Type (${array.length})`

            }

            return ""

    }

const selectedType = (value) =>{
 
    if(search.type.includes(value)) return true

    return false

}

const toggleTypeDropDown = () => {
   setTypeDropdown(!typeDrowdown)
} 

const toggleRangeDropDown = () => {
    setRangeDropdown(!rangeDrowdown)
 }

 const setPrice = (type,value) =>{

    const formFieldCopy = {...search}

    if(type === "min") {
       
        formFieldCopy.list_price.min = value
        setMinPriceValue("$" + formatNumber(value) )
        setSearch(formFieldCopy)


    } 

    if(type === "max") {
        formFieldCopy.list_price.max = value
        setMaxPriceValue("$" + formatNumber(value) )
        setSearch(formFieldCopy)
     } 

 }

 const toggleMinDropdown = () =>{
    setMinDropdown(!minDropdown)
 }

 const toggleMaxDropdown = () =>{
    setMaxDropdown(!maxDropdown)
 }

 const setLocation =(location) =>{
    setSearch(({ ...search, ...location}));
}

    return(
        <div className="quick-search">
        <ul>
          <li>
            <h3>Location</h3>
            <Autocomplete setLocation={setLocation} data={search}/>
          </li>
          <li>

            <h3>Type</h3>
            <div ref={typeWindowRef} className="input-container"> 
              <input  
                   onClick={toggleTypeDropDown} 
                   style={{cursor: "pointer"}} 
                   readOnly 
                   type="text" 
                   placeholder="Single Family" 
                   value={typeValue}
                   onChange={()=>{}}
                   
                   />
              <i onClick={toggleTypeDropDown}  className="fa-solid fa-angle-down"></i>

              {
                typeDrowdown && 
                        <div className="q-dropdown">

                        <h3>Property Type</h3>
                        <ul>
                            {propertyTypeList.map((list, index) =>(
                                <li 
                                    onClick={(e) => {updateField("type", list.id)}}
                                    key={index} 
                                    id={list.id}>
                                    {selectedType(list.id) ?  
                                    <i className="fa-solid fa-square-check"></i> :
                                    <i className="fa-regular fa-square"></i>}
                                    {list.name}
                                    </li>
                            ))}
                        </ul>
                        <div className="btn-container">
                            <button onClick={toggleTypeDropDown} className="main-btn">
                                Done
                            </button>
                        </div>

                        
                    </div>
                }
             
            </div>
            
          </li>
          <li>
            <h3>Price Range</h3>
            <div ref={rangeWindowRef} className="input-container">
              <input 
                  onClick={toggleRangeDropDown} 
                  style={{cursor: "pointer"}} 
                  type="text" 
                  placeholder="$200,000 - $300,000"
                  value={priceRangeValue}
                  onChange={()=>{}}
                  />

              <i 
                 onClick={toggleRangeDropDown} 
                 className="fa-solid fa-angle-down"></i>
              {
                rangeDrowdown && 
                        <div className="q-dropdown range-dropdown">

                        <h3>Price range</h3>

                        <div className="range-option">

                            <div className="r-option">    
                                <h3>Min</h3>
                                <div className="input-container">
                                    <input 
                                        readOnly 
                                        type="text"
                                        value={minPriceValue}
                                        onClick={toggleMinDropdown}
                                        onChange={()=>{}}
                                        />
                                   <i onClick={toggleMinDropdown} 
                                      className="fa-solid fa-angle-down"></i>

                                    {
                                        minDropdown &&
                                        <div className="q-dropdown price-list">
                                      <ul className="">
                                        {salePriceOptions.map((price,index) => (
                                            <li 
                                               onClick={()=>{setPrice("min",price);toggleMinDropdown()}}
                                               key={index} id={price}>
                                                ${formatNumber(price) }
                                            </li>
                                        ))}
                                      </ul>
                                   </div>
                                    }

                                   
                                </div>
                            </div> 

                            <div className="r-option">    
                                <h3>Max</h3>
                                <div className="input-container">
                                    <input 
                                        readOnly 
                                        type="text"
                                        value={maxPriceValue}
                                        onClick={toggleMaxDropdown}
                                        onChange={()=>{}}
                                        />
                                   <i onClick={toggleMaxDropdown} 
                                      className="fa-solid fa-angle-down"></i>

                                    {
                                        maxDropdown &&
                                        <div className="q-dropdown price-list">
                                      <ul className="">
                                        {salePriceOptions.map((price,index) => (
                                            <li 
                                               onClick={()=>{setPrice("max",price);toggleMaxDropdown()}}
                                               key={index} id={price}>
                                                ${formatNumber(price) }
                                            </li>
                                        ))}
                                      </ul>
                                   </div>
                                    }

                                   
                                </div>
                            </div> 

                        </div>
                      
                        <div className="btn-container">
                            <button onClick={toggleRangeDropDown} className="main-btn">
                                Done
                            </button>
                        </div>

                        
                    </div>
                }
            </div> 
          </li>
        
        </ul>
           
           <a href={searchUrl}>
               <button className="main-btn">Search</button>
           </a>
            
      </div>
    )

}

export default QuickSearch