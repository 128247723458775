
import Login from './Pages/login';
import Signup from './Pages/signup';
import SingleProperty from './Pages/SingleProperty';
import Home from './Pages/home';
import Listings from './Pages/listings';
import {Routes, Route} from "react-router-dom"
import MainLayout from './layout/main.layout';
import SpliLayout from './layout/split.layout';
import ListingLayout from './layout/listing.layout';


function App() {
  
  return (
    <>
      <div className="App">  
            <Routes>
              <Route path="/signup" element={<SpliLayout><Signup/></SpliLayout>}/>
              <Route path="/login" element={<SpliLayout><Login/></SpliLayout>}/>
              <Route path="/single_property" element={<MainLayout><SingleProperty/></MainLayout>}/>
              <Route path="/listings" element={<ListingLayout><Listings/></ListingLayout>}/>
              <Route path="/listings/buy" element={<ListingLayout><Listings/></ListingLayout>}/>
              <Route path="/listings/rent" element={<ListingLayout><Listings/></ListingLayout>}/>
              <Route path="/" element={<MainLayout><Home /></MainLayout>}/>
            </Routes>   
      </div>
    </>
     
  );
}

export default App;
