
export const statusList = [
    {
       id: "for_sale",
       name: "For Sale"
    },
    {
       id: "for_rent",
       name: "Rent"
    },
    {
       id: "sold",
       name: "Sold"
    },
    {
       id: "ready_to_build",
       name: "Ready to Build"
    },
    {
       id: "off_market",
       name: "Off Market"
    },
    {
       id: "new_community",
       name: "New Community"
    }
]