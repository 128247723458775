export const propertyTypeList = [
    {
       id: "single_family",
       name: "Single Family",
    },
    {
       id: "multi_family",
       name: "Multi-Family"
    },
    {
       id: "townhomes",
       name: "Townhomes"
    },
    {
       id: "condos",
       name: "Condo"
    },
    {
       id: "apartment",
       name: "Apartment"
    },
    {
       id: "duplex_triplex",
       name: "Duplex/Triplex"
    },
    {
       id: "farm",
       name: "Farm"
    },
    {
       id: "land",
       name: "Land"
    },
    {
       id: "mobile",
       name: "Mobile"
    },

]