import Filter from "../components/filter/Filter.jsx"
import URL from "../constants/urls.js"
import HttpRequest from "../httpRequest/HttpRequest.js"
import { useState, useEffect, useRef } from "react"
import { deepSearch } from "../Util/getValueByKey.js"
import CardLoading from "../components/loadingEffect/CardLoading/CardLoading.jsx"
import PropertyCard from "../components/propertyCard/PropertyCard.jsx"
import { updateParam, getParams } from "../Util/urlParcer.js"
import removeEmptyValues from "../Util/removeEmptyValues.js"
import deepCopy from "../Util/deepCopy.js"
import { useUserContext } from "../context/user/UserContext.jsx"
import Modal from "../components/modal/Modal.jsx"
import Login from "../components/Form/login/LoginForm.jsx"
import Map from "../components/map/Map.jsx"
import { formatNumber } from "../Util/formatNumber.js"
import { useLocation } from "react-router"
import { useMessageContext } from "../context/notification/Notification.jsx"
import { propertiesDemo } from "../Mock/propertyDemo.js"
import Footer from "../footer/footer.jsx"

const Listings = () =>{


  const {userAuthenticated, getUserFavoriteProperties, updateProperty} = useUserContext()
  const {error} = useMessageContext()
  const location = useLocation();
  const { pathname } = location
  
  const [search, setSearch] = useState({
                        city_zip : "",
                        city: "jacksonville",
                        state_code: "FL",
                        postal_code: "",
                        address: "",
                        type: [],
                        status: [],
                        list_price: {min: 0, max: 0},
                        baths: {min: 0, max: 0},
                        beds : {min: 0, max: 0},
                        limit : 50
                      })

  const init = {
        count: 0,
        total: 0,
        results : [],
  }

  const [loadingOverlay, setLoadingOverlay] = useState(false)
  const {loading, post, del} = HttpRequest({headers: {
    'Content-Type': 'application/json'
  }})

  const [properties, setProperties] = useState([])
  const [total, setTotal] = useState(0)
  const [loginModal, setLoginModal] =useState (false)
  
  useEffect(() => {
    const params = getParams("search");
    if (params) {
      setSearch((prevSearch) => ({
        ...prevSearch,
        ...Object.keys(params).reduce((acc, key) => {
          if (key in prevSearch) {
            acc[key] = params[key];
          }
          return acc;
        }, {})
      }));
    }
  }, []);

  const prevSearchRef = useRef(null);
  const timeoutRef = useRef(null)

  useEffect(() => {
    if (prevSearchRef.current && !deepEqual(prevSearchRef.current, search)){
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      const searchCopy = deepCopy(search)
      
      updateParam(removeEmptyValues(searchCopy, true), true, "search") 
      timeoutRef.current = setTimeout(() => {
        fetchProperties()
      }, 600)
      
    }else if(!prevSearchRef.current && !deepEqual(prevSearchRef.current, search)){
      const searchCopy = deepCopy(search)
      updateParam(removeEmptyValues(searchCopy, true), true, "search") 
      fetchProperties()
    }
    prevSearchRef.current = search;
    return () => {
      if (timeoutRef.current && (!deepEqual(prevSearchRef.current, search))) {
          clearTimeout(timeoutRef.current)
      }
  }
  }, [search]);

  const deepEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const fetchProperties = async () =>{
    if(properties.length !== 0){
      setLoadingOverlay(true)
    }
    const searchCopy = deepCopy(search)
    if (searchCopy.hasOwnProperty('address')) {
      delete searchCopy.address
    }
    const response = await post(URL.SEARCH, removeEmptyValues(searchCopy, true))
    setLoadingOverlay(false)
    console.log(response)
    if(response && response.error && response.error.code === 20){
     return
    }
    if(response.status >= 500 && response.status <= 599){
      error("Something went wrong on our end. Please try again later.")
      setTotal(propertiesDemo.length)
      setProperties(propertiesDemo)
    }
    else if(response.status !== 200 && response.body){
      error(response.body.message)
      setTotal(propertiesDemo.length)
      setProperties(propertiesDemo)
    }
    else if(response.status === 200 && (!response.body || deepSearch(response.body,["data","home_search"],init).total === 0)){
      error("We couldn't find properties with the provided location. Please try a different location.")
      setTotal(propertiesDemo.length)
      setProperties(propertiesDemo)
    }
    else if(response.status === 200 && response.body){
      const propertyData = deepSearch(response.body,["data","home_search"],init)
      setTotal(propertyData.total)
      setProperties(propertyData.results)
    }
    else {
      error("Something went wrong on our end. Please try again later.")
      setTotal(propertiesDemo.length)
      setProperties(propertiesDemo)
    }
  }

  const saveProperty = async (likedPropertyData) => {
    const response = await post(URL.LIKE_PROPERTY, likedPropertyData);
    if(response.status === 200){
        updateProperty(response.body)
    }
    else if(response.status === 401){
      window.location.reload()
    }
    else{
      console.error("Could not save property")
    }
  }

  const deleteProperty = async (likedPropertyData) => {
    const response = await del(URL.LIKE_PROPERTY, likedPropertyData);
    if(response.status === 200){
        updateProperty(likedPropertyData)
    }
    else if(response.status === 401){
      window.location.reload()
    }
    else{
      console.error("Could not delete property")
    }
  }

  const likeProperty = (likedPropertyData) => {
    if(!userAuthenticated()) {
      setLoginModal(true)
      return
    }
    const properties = getUserFavoriteProperties()
    const exist = properties.some(property => property.propertyId === likedPropertyData.propertyId)
    if(exist){
      deleteProperty(likedPropertyData)
    }else{
      saveProperty(likedPropertyData)
    }
  }

  const isFavorite = (id) => {
    if(!userAuthenticated()) return
    const properties = getUserFavoriteProperties()
    return properties.some(property => property.propertyId === id)
  }

  return (
      <>
          <Filter data={search} setData={setSearch}/>

          <div className={`listing-layout ${loadingOverlay ? 'loading-overlay' : ''}`}>
              {
                <Map properties={properties} 
                  zoom={10}
                  disableDefaultUI={false}
                  streetViewControl={false}
                  fullscreenControl={false}
                  styleElement={"sticky_map"}
                  loading={loading}
                /> 
              }

              {
                  properties.length === 0 ? 
                  <CardLoading layout={"grid-layout"}/> :
                  <div className="properties-section">

                    <div className="show-properties-header"> 
                      <h5>
                        {`Showing `} <b style={{fontWeight: "600"}}>{`${formatNumber(search.limit)}`}</b>  {` out of `}
                        <b style={{fontWeight: "600"}}>{`${formatNumber(total)}`}</b>{` result for `}
                            <span style={{fontStyle: "italic"}}>
                              {`"${search.address}"`}
                            </span>
                        </h5>
                   </div>
                    <div className="property_list_container">
                      { 
                        properties.map((property,index)=>(
                            <PropertyCard
                                singleProperty = {property}
                                imageKey = {"od-w1024_h768.jpg"}
                                key={index}
                                likeProperty={likeProperty}
                                isFavorite={isFavorite}
                            />
                            ))
                      }
                    </div>
                    <Footer Class={"listing-footer"} />
                  </div>
                    
              } 
          </div>

        <Modal isOpen={loginModal} setModalState={setLoginModal}>
           <Login/>
        </Modal>
    </>
    )
}

export default Listings